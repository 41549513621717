<template>
  <v-layout>
    <v-main :style="mini ? 'padding: 0 0 0 36px;' : 'padding: 0 0 0 200px;'">
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
  </v-layout>
</template>
<script>
export default {
  props: {
    mini: {
      type: Boolean,
      default: false
    }
  }
}
</script>
